<template>
  <div id="user">
    <van-nav-bar
      :title="$t('user.title')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <div class="userinfo" v-if="currentUser">
      <van-cell-group class="header">
        <van-cell is-link :to="{ name: 'userinfo' }">
          <van-image class="avatar" :src="currentUser.headimgurl" round />
          <span class="name">{{ currentUser.nickname }}</span>
        </van-cell>
      </van-cell-group>

      <van-cell-group class="mt-3">
        <van-grid>
          <van-grid-item icon="records" :text="$t('user.orders')" to="/orders" />
          <van-grid-item icon="coupon-o" :text="$t('coupon.coupon')" @click="showCouponPopup = true" />
          <van-grid-item icon="records" :text="$t('app.address_manage')" to="/address" />
          <van-grid-item icon="phone-o" :text="$t('user.cs_tel')" @click="callCS" />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="mt-3">
        <van-cell clickable is-link @click="showLanguagePopup = true">{{ $t('user.change_language') }}</van-cell>
        <van-cell clickable is-link @click="logout">
          <span class="text-danger">{{ $t('user.logout' )}}</span>
        </van-cell>
      </van-cell-group>

      <van-popup
        v-model="showLanguagePopup"
        round
        position="bottom"
      >
        <van-picker
          show-toolbar
          value-key="label"
          :columns="languages"
          :title="$t('user.change_language')"
          @cancel="showLanguagePopup = false"
          @confirm="switchLanguage"
        />
      </van-popup>

      <van-popup
        v-model="showCouponPopup"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px;"
      >
        <van-coupon-list
          class="userinfo-coupon"
          :coupons="enableCoupons"
          :disabled-coupons="disableCoupons"
          :chosen-coupon="chosenCoupon"
          :show-close-button="false"
          @exchange="handleExchageCoupon"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  NavBar, Grid, GridItem, CouponList, SwipeCell,
  Locale as VanLocale,
} from 'vant';
import Van_ZH_CN from 'vant/es/locale/lang/zh-CN';
import Van_ZH_HK from 'vant/es/locale/lang/zh-HK';
import { formatMoney, showErrorMessage } from '@/helpers';

Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CouponList);
Vue.use(SwipeCell);

/* global wx */

export default {
  name: 'User',
  mounted() {
    this.$store.dispatch('fetchMyCoupons');
  },
  data() {
    return {
      chosenCoupon: -1,
      showCouponPopup: false,
      showLanguagePopup: false,
      languages: [
        {
          label: '繁體中文',
          locale: 'zh_HK',
        },
        {
          label: '简体中文',
          locale: 'zh_CN',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      myCoupons: state => state.user.myCoupons,
    }),
    // 可用优惠券
    enableCoupons() {
      /* eslint-disable no-param-reassign */
      const coupons = [];
      const now = ~~(Date.now() / 1000);
      this.myCoupons.forEach((item) => {
        if (now < item.validity_period_timestamp[0] || now > item.validity_period_timestamp[1]) {
          // 過期
          item.reason = this.$t('coupon.disable_reasons.expired');
          return;
        }
        coupons.push(this.formatCouponData(item));
      });
      return coupons;
    },
    // 不可用优惠券
    disableCoupons() {
      const coupons = [];
      const enableIds = _.map(this.enableCoupons, 'id');
      this.myCoupons.forEach((item) => {
        if (~enableIds.indexOf(item.user_coupon_id)) {
          return;
        }
        coupons.push(this.formatCouponData(item));
      });
      return coupons;
    },
  },
  methods: {
    formatCouponData(coupon) {
      let valueDesc;
      let unitDesc;
      let condition;
      let description = '';
      if (coupon.discount_price) {
        valueDesc = formatMoney(coupon.discount_price, 'HKD');
        unitDesc = '';
      } else if (coupon.discount_percent) {
        valueDesc = coupon.discount_percent;
        unitDesc = '% OFF';
      }
      if (coupon.use_amount_condition) {
        condition = this.$t('coupon.use_amount_condition', { price: coupon.use_amount_condition });
      } else {
        condition = this.$t('coupon.no_condition');
      }
      if (coupon.new_user_only) {
        description = this.$t('coupon.new_user_only');
      }

      return {
        id: coupon.user_coupon_id,
        name: coupon.name,
        condition,
        description,
        startAt: coupon.validity_period_timestamp[0],
        endAt: coupon.validity_period_timestamp[1],
        reason: coupon.reason ? coupon.reason : '',
        value: 0,
        valueDesc,
        unitDesc,
      };
    },
    handleExchageCoupon(code) {
      this.$store.dispatch('exchangeCoupon', code).then(() => {
        // console.log(this.myCoupons);
        this.$toast.success(this.$t('coupon.exchanged'));
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        //
      });
    },
    callCS() {
      window.location.href = 'tel:+85236931234';
    },
    switchLanguage({ locale }) {
      if (locale !== localStorage.getItem('locale')) {
        localStorage.setItem('locale', locale);
        /* eslint-disable default-case */
        switch (locale) {
          case 'zh_CN':
            VanLocale.use('zh_CN', Van_ZH_CN);
            break;
          case 'zh_HK':
            VanLocale.use('zh_HK', Van_ZH_HK);
            break;
        }
        this.$i18n.locale = locale;
      }
      this.showLanguagePopup = false;
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        wx.checkJsApi({
          jsApiList: ['closeWindow'],
          success: ({ checkResult }) => {
            if (checkResult.closeWindow) {
              wx.closeWindow();
            } else {
              window.location.href = '/';
            }
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
  @import "../style/variables";
  #user {
    .userinfo {
      .header {
        padding: .6rem;
        .avatar {
          border: 1px solid $gray-3;
          img {
            width: 60px;
            height: 60px;
          }
        }
        .name {
          display: inline-block;
          padding: .7rem;
          font-size: 1.2rem;
          vertical-align: top;
        }
      }
    }
  }
  .userinfo-coupon {
    .van-coupon__corner {
      display: none!important;
    }
  }
</style>
