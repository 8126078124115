<template>
  <div id="user-info">
    <van-nav-bar
      :title="$t('userinfo.title')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-form @submit="onSave" label-width="5rem">
      <van-cell-group :title="$t('userinfo.bind_email')">
        <van-field
          v-model.trim="form.email"
          center
          clearable
          :label="$t('userinfo.email')"
          :placeholder="$t('userinfo.placeholder.email')"
          :rules="[{
            pattern: emailRexExp,
            message: $t('userinfo.message.email_validate'),
            trigger: 'onBlur',
          }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click.prevent="sendMailVerifyCode"
              :disabled="!emailEdited || !emailVerification || emailVerifyCodeSendLock > 0"
            >
              <span v-if="emailVerifyCodeSendLock">
                {{ $t('userinfo.resend' )}}({{ emailVerifyCodeSendLock }}s)
              </span>
              <span v-else>
                {{ $t('userinfo.send_verify_code') }}
              </span>
            </van-button>
          </template>
        </van-field>
        <van-field
          v-if="emailEdited"
          v-model.trim="form.email_verify_code"
          :label="$t('userinfo.email_verify_code')"
          :placeholder="$t('userinfo.placeholder.email_verify_code')"
        />
      </van-cell-group>
<!--      <van-cell-group :title="$t('userinfo.update_password')">-->
<!--      </van-cell-group>-->
    </van-form>

    <div class="m-3">
      <van-button round block type="info" @click="onSave">
        {{ $t('common.save') }}
      </van-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { NavBar } from 'vant';
import moment from 'moment';
import { showErrorMessage } from '@/helpers';

Vue.use(NavBar);

export default {
  name: 'UserInfo',
  mounted() {
    this.form.email = this.currentUser.email || '';
  },
  data() {
    return {
      emailRexExp: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
      // 郵箱驗證碼發送鎖定時間
      emailVerifyCodeSendLock: 0,
      // 郵箱驗證碼發送定時器
      emailVerifyCodeSendLockInteval: null,
      form: {
        email: '',
        email_verify_code: '',
        phone: '',
        phone_verify_code: '',
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      sendEmailVerifyCodeLockTime: state => state.user.sendEmailVerifyCodeLockTime,
    }),
    emailVerification() {
      return this.emailRexExp.test(this.form.email);
    },
    emailEdited() {
      if (this.form.email) {
        return this.currentUser.email ? this.currentUser.email !== this.form.email : true;
      }
      return false;
    },
  },
  watch: {
    sendEmailVerifyCodeLockTime: {
      handler(time) {
        const now = moment();
        if (time && now.isBefore(time)) {
          const lockTime = moment(time);
          this.emailVerifyCodeSendLock = lockTime.diff(now, 's');
          this.emailVerifyCodeSendLockInteval = setInterval(() => {
            if (this.emailVerifyCodeSendLock) {
              this.emailVerifyCodeSendLock--;
            } else {
              clearInterval(this.emailVerifyCodeSendLockInteval);
            }
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    sendMailVerifyCode() {
      if (this.emailVerification) {
        const params = {
          email: this.form.email,
          type: 'bind',
        };
        this.$api.user.sendEmail(params).then(() => {
          this.$store.commit('setSendEmailVerifyCodeLockTime', moment().add(60, 's').toDate());
        }).catch((e) => {
          showErrorMessage(e);
        }).finally(() => {
          this.$toast.clear();
        });
      }
    },
    onSave() {
      const params = {};
      if (this.emailEdited) {
        params.email = this.form.email;
        if (this.form.email_verify_code) {
          params.email_verify_code = this.form.email_verify_code;
        } else {
          this.$toast.fail(this.$t('userinfo.placeholder.email_verify_code'));
          return false;
        }
      }
      if (_.isEmpty(params)) {
        return false;
      }
      this.$toast.loading({
        message: this.$t('common.saving'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.user.save(params).then(({ data: response }) => {
        this.$store.commit('receiveUserInfo', response.data);
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
      return true;
    },
  },
};
</script>

<style lang="scss">

</style>
